header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header_container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ==== CTA ==== */

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ==== Socila ==== */

.header_social {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header_social::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ==== image === */

.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    padding: 1.5rem;
}
.me img {
    display: block;
    width: auto;
    margin-left: -2rem;
    object-fit: cover;
}

/* ==== Scroll Down === */

.scroll_down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-size: 0.9rem;
    font-weight: 300;
}

/* ======= MEDIA QUERIES (Medium Devices) ===== */

@media screen and (max-width: 1024px) {
    header {
       /* height: 68vh; */
    }
}

/* ======= MEDIA QUERIES (Small Devices) ===== */

@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header_social,
    .scroll_down {
        display: none;
    }
}