.portfolio_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}
.portfolio_item {
    background-color: var(--color-bg-variants);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}
.portfolio_item:hover {
    border-color: var(--color-bg-variants);
    background: transparent;
}
.portfolio_item-image {
    border-radius: 1.5rem;
    overflow: hidden;
    height: 249px;
}
.portfolio_item h3 {
    margin: 1.2rem 0 2rem;
}
.m-app {
    margin-top: 3rem;
}

/* ======= MEDIA QUERIES (Medium Devices) ===== */

@media screen and (max-width: 1024px) {
    .portfolio_container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* ======= MEDIA QUERIES (Small Devices) ===== */

@media screen and (max-width: 600px) {
    .portfolio_container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}